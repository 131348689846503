import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WeddingPage from './components/WeddingPage';
import Home from './components/Home';
import About from './components/About';
import RSVPForm from './components/RSVPForm';
import Photos from './components/Photos';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <WeddingPage />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/rsvp" element={<RSVPForm />} />
          <Route path="/photos" element={<Photos />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
