import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.css';


const WeddingPage = () => {
  return (
    <div className='wedding-page' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background2.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
      {/* Navbar */}
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/" className="nav-link" activeClassName="active" exact>
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/about" className="nav-link" activeClassName="active">
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/rsvp" className="nav-link" activeClassName="active">
              RSVP
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/photos" className="nav-link" activeClassName="active">
              Photos
            </NavLink>
          </li>
          <li className="nav-item">
          <a href="https://www.amazon.com/wedding/registry/2SI27UQINI5VG" className="nav-link">
              Registry
          </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default WeddingPage;