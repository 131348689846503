import React from 'react';
import './styles.css';

const Home = () => {
  return (
    <div className='home' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background1.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
        <div className="image-section">
            <img src="img/savethedate.jpg" alt="Wedding" />
        </div>
    </div>
  );
};

export default Home;