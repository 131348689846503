import React from 'react';
import './styles.css';

const About = () => {
  return (
    <div className='about-strip' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background1.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
      <h1>Our Epic Love Saga: The Chronicles of Hector & Strav-Pete</h1>
      <p>We're getting married!!! Come and celebrate with us!</p>
      <p>In a world where love knows no bounds, two souls collided in a cosmic whirlwind of destiny. Hector, hailing from the mystical lands of Waterbury, CT, and Strav-Pete, a radiant spirit from the vibrant realm of Port Chester, NY, embarked on a journey that would defy all odds.</p>
      <p>It was the era of the pandemic, a time when isolation reigned supreme. Fate intervened through the digital web of destiny, connecting our heroes through the enchanted realm of an online dating platform known as OkCupid. Little did they know, this virtual encounter would spark a flame that would illuminate their hearts.</p>
      <p>Bonding over their shared disdain for injustice and their fervent devotion to the realms of Marvel and music, Hector and Strav-Pete discovered a love that transcended dimensions. Their souls intertwined, fueled by a passion for humanity and a relentless drive to make the world a better place.</p>
      <p>But amidst their adventures, a pivotal moment arose when Hector unveiled the sacred lore of Star Wars to Strav-Pete. It was a revelation that cemented their bond, weaving their destinies even tighter together.</p>
      <p>As they stand on the precipice of matrimony, Hector and Strav-Pete eagerly anticipate the next chapter of their saga. Their hearts brimming with love, they look forward to every new adventure, knowing that together, they are unstoppable. And as they embark on the journey of growing their family, their love will continue to flourish, an eternal flame igniting the path ahead.</p>
      <p>Their story is a testament to the power of love, the resilience of the human spirit, and the unyielding bond that defies all odds. So join us, dear friends, as we celebrate the union of Hector and Strav-Pete, for their love story is a tale worthy of legend.</p>
    </div>
  );
};

export default About;