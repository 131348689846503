import React from 'react';

const RSVPForm = () => {
  /*const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState('');
  const [attendance, setAttendance] = useState('');
  const [guestName, setGuestName] = useState(''); // State for guest name

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };*/

  return (
    <div className='forms'style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background1.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
      {/* Embedded Google Form Code */}
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfVlt3LaCC3vQz7gA9_Vl_So7dADhqIwwlmt72x9lqI3YXPXA/viewform?embedded=true" 
        title="RSVP Form"
        background=""
        width="740"
        height="1000"
        padding-left="50px"
        frameborder="0"
        marginheight="2px"
        marginwidth="0"
        >
        Loading…
      </iframe>
    </div>
    /*<div className='forms'style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background1.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
      <h1>RSVP Here!</h1>
      <h2>*Save the date for Saturday, December 7th, 2024. Location in Bolton Connecticut.*</h2>
      <h2>*The venue will be emailed in the spring to everyone that RSVPs*</h2>
      <form onSubmit={handleSubmit}>
        <label className='leftcol'>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <label className='leftcol'>
          Guest Name:
          <input type="text" value={guestName} onChange={(e) => setGuestName(e.target.value)} />
        </label>
        <label className='rightcol'>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <label className='rightcol'>
          Additional Comment, (i.e. food allergie):
          <textarea value={response} onChange={(e) => setResponse(e.target.value)} />
        </label>
        <label>
          Will you be joining us?
          <div>
            <input
              type="radio"
              id="yes"
              name="attendance"
              value="yes"
              checked={attendance === 'yes'}
              onChange={(e) => setAttendance(e.target.value)}
            />
            <label htmlFor="yes">Yes</label>
          </div>
          <div>
            <input
              type="radio"
              id="no"
              name="attendance"
              value="no"
              checked={attendance === 'no'}
              onChange={(e) => setAttendance(e.target.value)}
            />
            <label htmlFor="no">No</label>
          </div>
          <div>
            <input
              type="radio"
              id="maybe"
              name="attendance"
              value="maybe"
              checked={attendance === 'maybe'}
              onChange={(e) => setAttendance(e.target.value)}
            />
            <label htmlFor="maybe">Maybe</label>
          </div>
        </label>
        <button className='button' type="submit">Submit RSVP</button>
      </form>
    </div>*/
  );
};

export default RSVPForm;