import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Photos = () => {
  return (
    <Carousel autoPlay infiniteLoop useKeyboardArrows dynamicHeight>
      <div>
        <img src="./img/20220409_194341.jpg" alt="Placeholder 1" />
        <p className="legend">Legend 1</p>
      </div>
      <div>
        <img src="./img/20220321_121315.jpg" alt="Placeholder 2" />
        <p className="legend">Legend 2</p>
      </div>
      <div>
        <img src="./img/20220521_154321.jpg" alt="Placeholder 3" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/20220604_182326.jpg" alt="Placeholder 4" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/20221104_171219.jpg" alt="Placeholder 5" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/20221023_163851.jpg" alt="Placeholder 6" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20221225_210159041.jpg" alt="Placeholder 7" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20221225_210221799.jpg" alt="Placeholder 8" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20221226_170217258.jpg" alt="Placeholder 9" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20230111_180514470.MP.jpg" alt="Placeholder 10" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20230213_152901309.jpg" alt="Placeholder 11" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20230423_012636880.MP.jpg" alt="Placeholder 12" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/at the beach.jpg" alt="Placeholder 13" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20230610_225656814.jpg" alt="Placeholder 14" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20230617_004901777.MP.jpg" alt="Placeholder 15" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20230617_025107984.jpg" alt="Placeholder 16" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/20221114_120555.jpg" alt="Placeholder 17" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/PXL_20231231_053422529.jpg" alt="Placeholder 18" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/beyonce.jpg" alt="Placeholder 19" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/chathamwater.jpg" alt="Placeholder 20" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/christmas.jpg" alt="Placeholder 21" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/christmaspup.jpg" alt="Placeholder 22" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/christmasornament.jpg" alt="Placeholder 23" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/nyc.jpg" alt="Placeholder 24" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/worldtradecenter.jpg" alt="Placeholder 25" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img src="./img/wedding.jpg" alt="Placeholder 26" />
        <p className="legend">Legend 3</p>
      </div>
    </Carousel>
  );
};

export default Photos;

